<!-- echarts图标组件 -->
<template>
  <div :id="refId ? refId : id" :style="styleStr
    ? styleStr
    : { width: width ? `${width}px` : '', height: height ? `${height}px` : '' }
    " class="my-echarts" :ref="refId ? refId : id"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Echarts",
  data() {
    return {
      id: "",
      chart: "",
    };
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {
          title: {
            text: "ECharts 示例",
          },
          tooltip: {},
          legend: {},
          xAxis: {
            type: "category",
            data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "销量",
              data: [820, 932, 901, 934, 1290, 1330],
              type: "line",
            },
          ],
        };
      },
    },
    width: {
      type: [String, Number],
      default: "",
    },
    refId: {
      type: String,
      default: "",
    },
    styleStr: {
      type: String,
      default: "",
    },
    height: {
      type: [String, Number],
      default: "",
    },
  },
  watch: {
    option: {
      handler(newVal, oldVal) {
        // console.log(this.chart)
        if (this.chart) {
          this.chart.dispose();
          this.init();
          // this.chart.hideLoading()
          // this.chart.setOption(newVal)
        } else {
          this.init();
        }
      },
      deep: true,
    },
  },
  created() {
    // 设置随机数id
    let t = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let len = t.length;
    let id = "";
    for (let i = 0; i < 32; i++) {
      id += t.charAt(Math.floor(Math.random() * len));
    }
    this.id = id;
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.chart.dispose();
  },
  methods: {
    loading() {
      this.chart.showLoading();
    },
    init() {
      this.$nextTick(() => {
        this.chart = echarts.init(document.getElementById(this.refId || this.id));
        this.chart.setOption(this.option);
        this.chart.on('click', (params) => {
          // 控制台打印点击的数据
          console.log(params);
          this.$emit('onclick', params);
          // 你可以在这里编写你的逻辑代码
          // 例如：根据点击的数据做一些操作
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.my-echarts {
  width: 100%;
  height: 100%;
}
</style>
